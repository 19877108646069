import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ProductCart from "./ProductCart";
import { CustomerSearchBar } from "./CustomerSearchBar";
import { useTotalAmount } from "../TotalAmountContext";
import { useItemCount } from "../ItemCountContext";
import PaymentModal from "../../Modal/Payment_Modal";
import {
  cash_in_session,
  cash_out_session,
  clear_cart_icon,
  customers_icon,
  return_icon,
} from "../../../assets";
import AddCustomer_Modal from "../../Modal/AddCustomer_Modal";
import { useSelector } from "react-redux";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useNavigate } from "react-router-dom"; // Updated import for navigation
import {
  addProducts,
  clearProducts,
} from "../../../Redux/Action/productsAction";
import { useDispatch } from "react-redux";
import {
  clearCustomer,
  setCustomer,
} from "../../../Redux/Action/getcustomerAction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import SessionFooter from "../sessioncomponents/SessionFooter"
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";


export const ProductBox = ({
  products,
  onRemoveProduct,
  onUpdateQuantity,
  userSelectedIndex,
  setUserSelectedIndex,
  isScannerActive,
  returnProducts,
  onClearCart,
  draftOrder,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setItemCount } = useItemCount();
  const { setTotalAmount } = useTotalAmount();
  const [localProducts, setLocalProducts] = useState([products]);
  const [modals, setModals] = useState(false);
  // const [userSelectedIndex, setUserSelectedIndex] = useState(null);


  const [selectedCustomerS, setSelectedCustomer] = useState({});
  // const localProducts = useSelector((state) => state.products.products);
  const { selectedCustomer } = useSelector((state) => state.getcustomer);
  const [selectedIndex, setSelectedIndex] = useState(0); // Track selected product index
  const productRefs = useRef([]);

  // console.log("selectedCustomer-->", selectedCustomer)
  // useEffect(() => {
  //   const productArray = Object.values(products);
  //   setLocalProducts(productArray);
  // }, [products]);

  // useEffect(() => {
  //   setItemCount(localProducts?.length);

  //   let total = 0;
  //   localProducts?.forEach((product) => {
  //     total += parseFloat(product.sale_price) * product.quantity;
  //   });
  //   setTotalAmount(total);
  // }, [localProducts, setItemCount, setTotalAmount]);

  
// console.log('returnProducts==>',returnProducts);

  useEffect(() => {
    const productArray = Object.values(products);
    // const draftArray = draftOrder?.products;
    // const returnArray = Object.values(returnProducts || []);
    const returnArray = Object.values(returnProducts || []).map(product => ({ ...product, isReturn: true }));
    // console.log("RETUTTTTT", returnArray);
    // console.log("hzoiszldnfsdvf===?>>>>>",[...productArray,...returnArray]);
    
    setLocalProducts([...productArray,...returnArray]);
  }, [products, returnProducts]);

  useEffect(() => {
    setItemCount(localProducts?.length);

    let total = 0;
    localProducts?.forEach((product) => {
      const productTotal = parseFloat(product.sale_price || product.rate) * product.quantity;
      total += product.isReturn ? -productTotal : productTotal;
      // total += parseFloat(product.price || product?.rate) * product.quantity;
    });
  // console.log("TOTAL-------->", total);

    setTotalAmount(total);

  }, [localProducts, setItemCount, setTotalAmount]);

  

  // useEffect(() => {
  //   const productArray = draftOrder ? Object.values(draftOrder.products) : [];
  //   setLocalProducts(productArray);
  //   setItemCount(productArray.length);

  //   let total = 0;
  //   productArray.forEach((product) => {
  //     total += parseFloat(product.price) * product.quantity;
  //   });
  //   setTotalAmount(total);
  // }, [draftOrder, setItemCount, setTotalAmount]);

  useEffect(() => {
    // console.log('products changed', products);
    if (products?.length === 0 && returnProducts?.length ===0) {
      setLocalProducts([]);
    }
  }, [products,returnProducts]);



  // useEffect(() => {
  //   if ( isScannerActive){
  //   setSelectedIndex(null) 
  //   setUserSelectedIndex(null)
  //   }
  // },[isScannerActive,setUserSelectedIndex,setSelectedIndex]);

  
  const handleClearCart = () => {
    // console.log('handleClearCart called');
    dispatch(clearProducts());
    dispatch(clearReturnProducts())
    dispatch(clearCustomer());
    setItemCount(0);
    setTotalAmount(0);
    setLocalProducts([]);
    setSelectedIndex(null);
  };

  const removeCustomer = () => {
    dispatch(clearCustomer());
    // setSelectedCustomer({});
  };

  const handleKeyDown = (e) => {
    // Normalize Arabic keyboard input to corresponding English keys
    const key = e.key.toLowerCase();
    const normalizedKey = {
      "ـ": "j",  // Arabic "ض" is similar to English "J"
      "،": "k",  // Arabic "ك" is similar to English "K"
      "ٍ": "s",  // Arabic "س" is similar to English "S"
      "آ": "n",  // Arabic "ن" is similar to English "N"
      "ِ": "a",  // Arabic "ا" is similar to English "A"
      "/": "l",  // Arabic "ل" is similar to English "L"
      "'": "m",  // Arabic "م" is similar to English "M"
    }[key] || key;
  
    if (!modals) {
      if (PaymentModal === true) {
        setSelectedIndex(null);
        return;
      }
      if (normalizedKey === "arrowdown") {
        e.preventDefault();
        if (e.shiftKey) {
          setUserSelectedIndex(localProducts.length - 1); // Set to last product in cart
        } else {
          e.preventDefault();
          setUserSelectedIndex((prev) => Math.min(prev + 1, localProducts.length - 1));
        }
        // e.preventDefault();
        // setUserSelectedIndex((prev) => Math.min(prev + 1, localProducts.length - 1));
      } else if (normalizedKey === "arrowup") {
        e.preventDefault();
        setUserSelectedIndex((prev) => Math.max(prev - 1, 0));
      } else if (normalizedKey === "delete") {
        e.preventDefault();
        if (localProducts[userSelectedIndex]) {
          onRemoveProduct(localProducts[userSelectedIndex]);
        }
      } else if (e.shiftKey && normalizedKey === "backspace") {
        handleClearCart();
      // } else if (normalizedKey === "=") {
      //   e.preventDefault();
      //   if (localProducts[userSelectedIndex]) {
      //     const newQuantity = localProducts[userSelectedIndex].quantity + 1;
      //     onUpdateQuantity(localProducts[userSelectedIndex].prod_id, newQuantity);
      //   }
      // } else if (normalizedKey === "-") {
      //   e.preventDefault();
      //   if (localProducts[userSelectedIndex] && localProducts[userSelectedIndex].quantity > 1) {
      //     const newQuantity = localProducts[userSelectedIndex].quantity - 1;
      //     onUpdateQuantity(localProducts[userSelectedIndex].prod_id, newQuantity);
      //   }
      }
    }
  };

  useLayoutEffect(() => {
    if (userSelectedIndex !== null && productRefs.current[userSelectedIndex]) {
      productRefs.current[userSelectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [userSelectedIndex, localProducts]);
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [localProducts, selectedIndex, modals, userSelectedIndex, setUserSelectedIndex]);
  
  useEffect(() => {
    if (productRefs.current[userSelectedIndex]) {
      productRefs.current[userSelectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      const scrollToSelectedProduct = () => {
        productRefs.current[userSelectedIndex].scrollIntoView({
          behavior: "smooth",
          block: "center", // Change block to 'center' for more natural positioning
          inline: "nearest", // Ensures horizontal scrolling is handled naturally
        });
      };
  
      // Ensure that the scroll animation is smooth and aligns the product properly
      requestAnimationFrame(scrollToSelectedProduct);
    }
  }, [userSelectedIndex]);
  

  // const handleKeyDown = (e) => {
  //   if(!modals){
  //   // if (e.ctrlKey && e.key === "Enter") {
  //   //   setSelectedIndex(null);
  //   //   return;
  //   // }
  //   if (PaymentModal == true) {
  //     setSelectedIndex(null);
  //     return;
  //   }
  //   if (e.key === "ArrowDown") {
  //     e.preventDefault();
  //     setUserSelectedIndex((prev) => Math.min(prev + 1, localProducts.length - 1));
  //   } else if (e.key === "ArrowUp") {
  //     e.preventDefault();
  //     setUserSelectedIndex((prev) => Math.max(prev - 1, 0));
  //   } else if (e.key === "Delete") {
  //     e.preventDefault();
  //     if (localProducts[userSelectedIndex]) {
  //       onRemoveProduct(localProducts[userSelectedIndex]);
  //       // setUserSelectedIndex((prev) => prev - 1); 
  //       // setSelectedIndex(null);
  //     }
  //   } else if (e.shiftKey && e.key === "Backspace") {
  //     handleClearCart();
  //   } else if (e.key === "=") {
  //     e.preventDefault();
  //     // Increase quantity for selected product
  //     if (localProducts[userSelectedIndex]) {
  //       const newQuantity = localProducts[userSelectedIndex].quantity + 1;
  //       onUpdateQuantity(localProducts[userSelectedIndex].prod_id, newQuantity);
  //     }
  //   } else if (e.key === "-") {
  //     e.preventDefault();
  //     // Decrease quantity for selected product
  //     if (
  //       localProducts[userSelectedIndex] &&
  //       localProducts[userSelectedIndex].quantity > 1
  //     ) {
  //       const newQuantity = localProducts[userSelectedIndex].quantity - 1;
  //       onUpdateQuantity(localProducts[userSelectedIndex].prod_id, newQuantity);
  //     }
  //   }
  // }

  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [localProducts, selectedIndex, modals]);

  // useEffect(() => {
  //   if (productRefs.current[selectedIndex]) {
  //     productRefs.current[selectedIndex].scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //     });
  //   }
  // }, [selectedIndex]);

 
  // useEffect(() => {
  //   if (localProducts.length > 0) {
  //     setUserSelectedIndex(localProducts.length - 1); // Update selection to new product
  //   } else {
  //     setUserSelectedIndex(null);
  //   }
  // }, [localProducts]);
  // console.log("localProducts==>",localProducts);
  
  return (
    <div className="flex ms-4 flex-col w-[102%] h-[calc(138vh-4rem)]">
      <CustomerSearchBar removeCustomer={removeCustomer} />
      <div className="flex flex-col text-sm mt-2 mb-2 tracking-wide text-black whitespace-nowrap bg-white rounded-lg border border-solid border-stone-300 w-full h-full overflow-hidden">
        <header className="flex justify-between px-4 py-2 text-base font-semibold tracking-wide bg-[#FCC71D] text-zinc-800">
          <div>{t("Product")}</div>
          <div className="flex items-center justify-center">
            <div className="flex mx-12">{t("Qty")}</div>
            <div className="flex me-7 ">{t("Total")}</div>
            <img
              src={clear_cart_icon}
              alt="clear_cart_icon"
              onClick={handleClearCart}
              className={`h-6 w-6 cursor-pointer transition-transform transform ${
                i18n.language == "ar" ? "scale-x-[-1]" : ""
              }`}
            />
          </div>
        </header>
        <main className="flex flex-col overflow-y-auto flex-1 custom-scrollbar">
          {localProducts?.map((product, index) => {
            // console.log("product--_>", product);
            
            return (  
            <ProductCart
              key={index}
              ref={(el) => (productRefs.current[index] = el)}
              product={product}
              onDelete={onRemoveProduct}
              onUpdateQuantity={onUpdateQuantity}
              isSelected={userSelectedIndex === index} // Pass selection state
              isScannerActive={isScannerActive}
              userSelectedIndex={userSelectedIndex}
            />
          )})}
        </main>
      </div>
      <SessionFooter
        setModals={setModals}
        localProducts={localProducts}
        setSelectedIndex={setSelectedIndex}
      />
    </div>
  );
};
